import React from 'react'
import Paragraph from '../../utils/Paragraph'
export default function SetTile(props) {
    return (
        <div className="px-8 py-12 transition-all ease-in-out duration-500 transform rounded-tr-50px hover:rounded-tr-75px hover:-translate-y-1 group rounder-tl-md rounded-br-md rounded-bl-md bg-white flex flex-col items-center justify-start">
            <img src={props.image} className="lg:mb-12 mb-4 lg:w-32 lg:h-32 w-24 h-24 object-contain transform transition-all duration-500 ease-in-out group-hover:scale-110"></img>
            <Paragraph className="text-center text-grayText">{props.description}</Paragraph>
        </div>
    )
}
