import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerSlide from './BannerSlide';
export default function BannerSlider(props) {
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 6000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className={`flex flex-col w-full`}>
            <Slider {...settings} className="w-full h-full">
                <BannerSlide image={"/images/banner1.jpg"}></BannerSlide>
                <BannerSlide image={"/images/banner2.jpg"}></BannerSlide>
                <BannerSlide image={"/images/banner3.jpg"}></BannerSlide>
            </Slider>
        </div>
    )
}
