import React, { forwardRef } from "react";
import { Link } from "gatsby";
const Button = forwardRef((props, ref) => {
    const {
        variant = props.variant,
        children,
        active,
        className,
        portfolio,
        loading = false,
        disabled = false,
        ...rest
    } = props;
    const classes = {
        root:
            "relative w-auto button-shadow rounded-md px-8 flex justify-center items-center h-12 md:text-base text-sm font-lato font-bold cursor-pointer transition duration-300 transform hover:-translate-y-2",
    };
    var rootClassName = "";
    if (portfolio)
        rootClassName = "relative w-auto button-shadow rounded-md px-8 flex justify-center items-center h-10 md:text-base text-sm font-lato font-bold cursor-pointer transition duration-300 transform hover:-translate-y-2" + " " + className;
    else
        rootClassName = classes.root + " " + className;
    return (


        props.type === "externalLink" ?
            <a
                ref={ref}
                className={rootClassName}
                disabled={disabled}
                href={props.href}
                target="_blank"
                rel="noreferrer"
                {...rest}
            >
                {props.children}
            </a>
            :
            props.type === "internalLink" ?
                <Link
                    ref={ref}
                    className={rootClassName}
                    disabled={disabled}
                    to={props.to}
                    {...rest}
                >
                    {props.children}
                </Link>
                :
                <button
                    aria-pressed={active}
                    data-variant={variant}
                    ref={ref}
                    className={rootClassName}
                    disabled={disabled}
                    onClick={props.onClick}
                    {...rest}
                >
                    {props.children}
                </button>

    )
});
export default Button;