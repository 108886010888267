import React from 'react'

export default function H1(props) {
    return (
        <h1 className={`lg:text-6xl md:text-5xl text-4xl font-bold ${props.className}`}>
            {/* <div class="bg-blue-100">
                <span class="bg-clip-text text-transparent bg-gradient-to-b from-yellow-300 to-purple-700">Text example</span>
            </div> */}
            {props.children}
        </h1>
    )
}
